$(document).ready(function() {
    $('.owl-carousel').owlCarousel({
        margin: 10,
        nav:true,
        dots: false,
        navText : [
            //left
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M15 6l-6 6l6 6" /></svg>',
            //right
            '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>'
        ],
        rewind: false,
        autoplay:true, 
        autoplayTimeout:5000,
        autoplayHoverPause: true,
        rewind: true, //el carrusel rebobina al primer elemento después de llegar al último 
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:5
            }
        }
        
    })



    // Encuentra los botones de navegación generados por Owl Carousel
    const nextButton = $('.owl-nav .owl-next');
    const prevButton = $('.owl-nav .owl-prev');

    // Modifica los atributos de los botones
    if (nextButton.length) {
        nextButton.attr('role', 'button');
        nextButton.attr('aria-label', 'Siguiente');
    }
    if (prevButton.length) {
        prevButton.attr('role', 'button');
        prevButton.attr('aria-label', 'Anterior');
    }


});

